/* ======================= AUTH PAGES --- LOGIN ==================== */
.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #2980AB;
}

.col-55 {
  width: 50%;
  padding: 70px 25px 0px 105px;
  height: 100%;
}

.auth-logo {
  width: 200px;
  height: 105px;
  margin-bottom: 25px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.sec-quotes {
  width: 100%;
  /* min-height: 100%; */
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 55px;
}

.a-quote h5 {
  color: #fff;
  font-size: 1.2rem !important;
  font-weight: 700;
  /* line-height: 30px; */
  width: 83%;
  text-wrap: wrap;
  margin-bottom: 20px;
  /* overflow-y: scroll; */
  /* height: fit-content; */
}

.a-quote h6 {
  color: #fff;
  font-size: 0.9rem !important;
  font-weight: 500;
}

.col-45 {
  width: 45%;
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-screen-img {
  width: 50%;
  height: 100vh;
}

.form-area {
  background-color: #f1f1f1;
  width: 90%;
  height: 95%;
  border-radius: 15px;
  padding: 75px 55px !important;
}

.form-area h6 {
  font-size: 2rem !important;
  font-weight: 500;
  text-transform: capitalize;
  color: #2980AB;
}

.form-area h5 {
  font-size: 3.5rem !important;
  font-weight: 800;
  /* font-style: italic; */
  text-transform: lowercase;
  line-height: 38px;
  margin-bottom: 30px;
  color: #2980AB;
}

.lform {
  width: 100% !important;
  height: calc(100% - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lform form {
  width: 100% !important;
}

.pass {
  position: relative;
}

.pass small {
  position: absolute;
  top: 78%;
  right: 8px;
  transform: translateY(-78%);
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;
}

/* ================ DASHBOARD  ========== */
.d-card {
  box-shadow: 2px 2px 2px #00000053;
  border-radius: 10px;
  min-height: 120px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.d-c-icon {
  font-size: 56px;
  font-weight: 800;
  /* color: #2980AB; */
  color: #2980AB;
}

.d-c-details small {
  font-size: 14px !important;
  font-weight: 500;
  color: #333;
}

.d-c-details h5 {
  font-size: 42px !important;
  /* color: #B81E23; */
  color: #333;
  font-weight: 700 !important;
}

/* ========================= USER MANAGEMENT PAGES ============ */
/* ================ ROLES FORMS ========== */
.bg-none {
  background: none !important;
}




/* ========================= PROFILE PAGES ============ */
.acc0img {
  height: 250px;
  width: 250px;
  border-radius: 12px;
  background-color: antiquewhite;
}


/* ========================= SITE PAGES ============ */
/* ================ SITES ========== */
.nav-container {
  display: none;
}

.hamburger {
  display: none;
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 10px;
}

.single-item {
  display: none;
}

.page-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  background-color: #fff; /* Set your desired background color */
  border-radius: 8px; /* Optional: Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Box shadow */
  padding: 10px;
}

.page-nav li {
  margin: 10px;
}

.mobile-page-nav {
  display: none;
}

/* ================ SITES LISTS ========== */
.site-img {
  height: 120px;
  width: 120px;
  border-radius: 12px;
  background-color: aqua;
}

/* ======= SITE DETAILS ========= */
.si-sec-b h6 {
  color: #333;
  font-size: 13px;
  font-weight: 500;
}

.si-sec-b p,
.si-sec-b li {
  font-size: 16px;
  font-weight: 700;
  color: #b5151a;
}

.si-sec-b li {
  list-style: disc;
}


/* ========================= MY ACCOUNT PAGES ============ */
/* ================ EDIT ACCOUNT ========== */

.profile-pic-large {
  width: 300px;
  height: 300px;
  border-radius: 12px;
  border: 6px solid #aaa7ff;
  background-color: #2980AB;
}