/* Styling for the datatable */
.card {
    box-shadow: 2px 2px 2px #ccc !important;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 6px !important;
    /* box-shadow: 2px 2px 2px 1px #ccc !important; */
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    /* background-color: #95A5A6; */
    font-size: 15px !important;
    font-weight: 500;
}

.table td {
    font-size: 13px !important;
}

/* Styling for pagination */
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
}

.pagination li {
    display: inline-block;
    margin: 0 5px;
}

.pagination a {
    color: #333;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
}

.pagination a.active {
    background-color: #007bff;
    color: #fff;
}