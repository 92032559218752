button {
    background: none;
    outline: none;
}

.btn-a {
    background-color: #2980AB;
    color: #fff;
    padding: 9px 1.5rem;
    border: 1px solid #ccc;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
}

.btn-a:hover {
    background-color: #B81E23;
}

.btn-b {
    background-color: #fff;
    color: #2980AB;
    padding: 9px 1.5rem;
    border: 1px solid #ccc;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
}

.btn-b:hover {
    color: #b5151a;
}

.btn-c {
    background-color: #b5151a;
    color: #fff;
    padding: 9px 1.5rem;
    border: 1px solid #ccc;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
}

.btn-c:hover {
    background-color: #2980AB;
}

.btn-d {
    background-color: #fff;
    color: #b5151a;
    padding: 9px 1.5rem;
    border: 1px solid #ccc;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
}

.btn-d:hover {
    color: #2980AB;
}

.in-nav {
    padding: 9px 15px !important;
    margin-right: 7px;
}
.sm-c {
    padding: 6px 15px;
}