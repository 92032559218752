@media only screen and (max-width: 600px) {
    .col-45 {
        width: 100%;
    }

    .col-55 {
        width: 0%;
        display: none;
    }

    .form-area {
        overflow-y: auto;
    }

    .form-area h6 {
        font-size: 1.1rem !important;
        font-weight: 500;
        text-transform: capitalize;
        color: #2980AB;
    }

    .form-area h5 {
        font-size: 1.5rem !important;
        font-weight: 800;
        /* font-style: italic; */
        text-transform: lowercase;
        line-height: 38px;
        margin-bottom: 30px;
        color: #2980AB;
    }

    .nav-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .hamburger {
        display: block;
    }

    .single-item {
        display: block;
        flex-grow: 1;
    }

    .page-nav {
        display: none;
    }

    .mobile-page-nav.open {
        list-style: none;
        display: flex;
        flex-direction: column !important;
        position: absolute;
        top: 172px;
        right: 20px;
        width: 80%;
        max-width: 300px;
        padding: 15px 15px !important;
        background-color: #fff;
        /* Set your desired background color */
        border-radius: 8px;
        /* Optional: Rounded corners */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        /* Optional: Box shadow */
        z-index: 1000;
    }

    .mobile-page-nav li {
        margin-bottom: 10px;
    }
}